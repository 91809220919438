import { MonitorService } from 'src/app/shared/services/monitor.service';
import { Inject, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SignalRService } from 'src/app/shared/services/signalR.service';
import { UsuarioPerfilEnum } from '../enum/UsuariosEnum';
import { BaseRequestResult } from '../models/BaseRequestResult.model';
import { UserSuporte } from '../models/ServicoVeiculoSuporte.model';
import { OneSignalService } from './oneSignal.service';
import { KeycloakService } from '@mottu-ops/keycloak-angular';
import {
  UsuarioSuporte,
  UsuarioSuporteModel,
} from '../models/PwaSuporte.model';
import { CurrentUser } from '../models/CurrentUser.model';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SnackbarService } from './snackBar.service';
import { UsuarioDataModel } from '../models/Usuario.model';
import { DOCUMENT } from '@angular/common';
import { MonitorRolesEnum } from 'src/app/components/auth/utils/monitor-roles.enum';
import { UserAgentService } from './user-agent.service';
import { AuthService } from '@mottu-ops/sso';
import { AuditUserModel } from '../models/audit-user.model';

@Injectable()
export class InternalAuthService {
  public currentUserSubject = new BehaviorSubject<boolean>(false);
  public currentUser;
  private baseUrl = environment.baseUrl;
  private baseUrlMonitor = environment.baseUrlMonitor;
  storages = [
    'isSupportUser',
    'boraboraUser',
    'currentUser',
    'isRenavamUser',
    'isFabricaUser',
    'isGuinchoUser',
    'NotificacoesSuporteRua',
    'NotificacoesRecolhimento',
    'boardParam',
    'mottuFieldApp',
  ];

  get isLoggedIn() {
    return this.currentUserSubject.asObservable();
  }

  get userLogged(): BaseRequestResult<CurrentUser<UsuarioSuporte>> {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  get boraboraUser() {
    return JSON.parse(localStorage.getItem('boraboraUser'));
  }

  get isSupportUser(): boolean {
    if (!this.userLogged) {
      return false;
    }
    const isSuportUser = JSON.parse(localStorage.getItem('isSupportUser'));
    return isSuportUser ?? false;
  }

  get isRenavamUser() {
    if (!this.userLogged) {
      return false;
    }
    return (
      this.userLogged.dataResult.data.perfilId == UsuarioPerfilEnum.Renavam
    );
  }

  get isFabricaUser() {
    if (!this.userLogged) {
      return false;
    }
    return (
      this.userLogged.dataResult.data.perfilId == UsuarioPerfilEnum.Fabrica
    );
  }

  get isGuinchoUser() {
    if (!this.userLogged) {
      return false;
    }

    return (
      this.userLogged?.dataResult?.data?.perfilId ==
        UsuarioPerfilEnum.Guincho ||
      this.checkUserRoles([MonitorRolesEnum.FLEET_THIRD_ADMIN])
    );
  }

  get isInsumoConsumer() {
    if (!this.userLogged) {
      return false;
    }

    return (
      this.userLogged?.dataResult?.data?.perfilId ==
        UsuarioPerfilEnum.Guincho
    );
  }

  get veiculoLogado() {
    const veiculo = JSON.parse(localStorage.getItem("veiculoSelecionado"));
    return veiculo?.length ? veiculo[0] : veiculo;
  }

  get usuario(): string {
    const usuario = this.userLogged.dataResult?.data?.nome;
    if (usuario) { return usuario; }
    return '';
  }

  get currentUserLogged(): UsuarioDataModel {
    if (this.userLogged && this.userLogged?.dataResult) {
      const user = this.userLogged?.dataResult;

      if (user && user?.data) { return user?.data; }
    }

    return null;
  }

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private snackBarService: SnackbarService,
    private signalRService: SignalRService,
    private monitorService: MonitorService,
    private oneSignalService: OneSignalService,
    private keycloak: KeycloakService,
    private userAgentService: UserAgentService,
    private authService: AuthService
  ) {}

  login(user: any) {
    return this.http
      .post(this.baseUrlMonitor + 'api/v1/IAM/authentication', user)
      .pipe(
        map((response: any) => {
          this.clearLocalStorage();
          if (response.dataResult) {
            this.setPlayerId(response.dataResult.data.id.toString());
            localStorage.setItem('currentUser', JSON.stringify(response));
            this.setRouteToRedirect(response.dataResult.data);
          }
          return response;
        })
      );
  }

  async authorize() {
    return this.http
      .post(this.baseUrlMonitor + 'api/v1/IAM/user', {
        token: this.authService.credentials.accessToken,
      })
      .toPromise()
      .then((response: any) => {
        this.clearLocalStorage();
        if (response.dataResult) {
          this.setPlayerId(response.dataResult.data.id.toString());
          localStorage.setItem('currentUser', JSON.stringify(response));
        } else if (response.erro) {
          this.keycloak.logout(window.location.origin);
        }
      })
      .catch(() => {
        this.keycloak.logout(window.location.origin);
      });
  }

  setPlayerId(id) {
    try {
      const playerId = localStorage.getItem('playerId');

      if (playerId && playerId != 'undefined') {
        this.oneSignalService.setExternalUserId(playerId, id).subscribe();
        localStorage.setItem('playerId', playerId);
      }
    } catch (error) {}
  }

  async redirect() {
    if (this.userLogged) {
      await this.setRouteToRedirect(this.userLogged.dataResult.data);
      const playerId = document.location.hash.split('=')[1];
      localStorage.setItem('playerId', playerId);
    }
  }

  async setRouteToRedirect(usuarioLogado: UsuarioSuporteModel) {
    if (this.keycloak.isLoggedIn()) {
      const pitStopUser = this.authService.validateRoles([MonitorRolesEnum.FLEET_PITSTOP]);
      const adminUser = this.authService.validateRoles([MonitorRolesEnum.FLEET_ADMIN]);

      if (pitStopUser && !adminUser) {
        return this.redirectLogin(['/home/pitstop']);
      }

      switch (usuarioLogado.perfilId) {
        case UsuarioPerfilEnum.Usuario:
          this.keycloak.logout(window.location.origin);
          break;
        case UsuarioPerfilEnum.AuxiliarRua:
        case UsuarioPerfilEnum.Suporte:
          localStorage.setItem('isSupportUser', JSON.stringify(true));
          return this.redirectLogin(['/veiculo-selecao']);
        case UsuarioPerfilEnum.Delivery:
          localStorage.setItem('boraboraUser', JSON.stringify(true)); // Isso aqui ainda é usado?
          return this.redirectLogin(['/home/monitor']);
        case UsuarioPerfilEnum.Portaria:
          return this.redirectLogin(['/movimentacao/base']);
        case UsuarioPerfilEnum.Renavam:
          localStorage.setItem('isRenavamUser', JSON.stringify(true));
          return this.redirectLogin(['/home/unidades']);
        case UsuarioPerfilEnum.Fabrica:
          localStorage.setItem('isFabricaUser', JSON.stringify(true));
          return this.redirectLogin(['/home/fabrica']);
        case UsuarioPerfilEnum.Guincho:
        case UsuarioPerfilEnum.TerceiroAdmin:
          return this.redirecionaTerceiro(usuarioLogado);
        default:
          if (
            !usuarioLogado.perfis.includes(UsuarioPerfilEnum.Suporte) &&
            !usuarioLogado.perfis.includes(UsuarioPerfilEnum.AuxiliarRua)
          ) {
            return this.redirectLogin(['/home/servicos-template/leaflet']);
          }
      }
    }
  }

  redirecionaTerceiro(usuarioLogado: UsuarioSuporteModel) {
    const isMobile = this.userAgentService.isMobile;

    if (
      (isMobile && this.checkUserRoles([MonitorRolesEnum.FLEET_THIRD_ADMIN])) ||
      usuarioLogado.perfis.includes(UsuarioPerfilEnum.Guincho)
    ) {
      return this.redirectLogin(['/veiculo-selecao/guincho']);
    } else if (
      usuarioLogado.perfis.includes(UsuarioPerfilEnum.TerceiroAdmin) &&
      !isMobile
    ) {
      return this.redirectLogin(['/terceiros']);
    }
  }

  redirectLogin(page) {
    const mobileInterface = window['PWAMobile'];
    if (mobileInterface) {
      window['PWAMobile'].SetUserData(JSON.stringify(localStorage));
    }

    const returnUrl = this.route.snapshot.queryParams.returnUrl;

    if (returnUrl) {
      this.router.navigateByUrl(returnUrl);
      return;
    }

    this.router.navigate(page);
  }

  resetSenha(credenciais) {
    return this.http.post(this.baseUrl + 'mottuweb/resetSenha', credenciais);
  }

  async logout() {
    const isSupportUser = JSON.parse(localStorage.getItem('isSupportUser'));
    const SupportPerfis = [
      UsuarioPerfilEnum.Suporte,
      UsuarioPerfilEnum.TerceiroAdmin,
      UsuarioPerfilEnum.Guincho,
    ];
    const isSupportPerfil = SupportPerfis.includes(
      this.currentUserLogged?.perfilId
    );
    const isLogoutFromField = isSupportUser || isSupportPerfil;

    isLogoutFromField ? this.desvincularUsuarioPwa() : await this.logoutAdmin();
  }

  async logoutAdmin() {
    this.clearLocalStorage();
    this.signalRService.disconnectSignalR();
    this.currentUserSubject.next(false);
    await this.keycloak.logout(this.document.location.origin);
  }

  logoutField() {
    try {
      const playerId = localStorage.getItem('playerId');
      if (playerId && playerId != 'undefined') {
        this.oneSignalService.setExternalUserId(playerId, '').subscribe();
      }
    } catch (error) {}
  }

  clearLocalStorage() {
    this.storages.forEach((storage) => {
      localStorage.removeItem(storage);
    });
  }

  checkUserRoles(roles: string[]) {
    return this.authService.validateRoles(roles, 'or');
  }

  desvincularUsuarioPwa() {
    this.monitorService
      .desvincularUserVeiculo(this.userLogged.dataResult.data.id)
      .subscribe((response: BaseRequestResult<UserSuporte>) => {
        if (!response.erro) {
          this.logoutField();
          this.logoutAdmin();
        } else {
          this.snackBarService.showSnackBar(response.mensagemErro, 'error');
        }
      }),
      (error) => {
        this.snackBarService.showSnackBar(error.error.mensagemErro, 'error');
      };
  }

  sendAuditData(data: AuditUserModel) {
    return this.http.post(this.baseUrlMonitor + 'api/v1/IAM/audit-user', data);
  }
}
