import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import {
    HubConnection,
    HubConnectionBuilder
} from "@aspnet/signalr";
import { ServicosService } from "src/app/components/pages/servicos-template/servicos.service";
import { environment } from "src/environments/environment";
import { NotificacaoService } from "./notificacao.service";

@Injectable({
  providedIn: "root",
})
export class SignalRService {
  private hubConnection: HubConnection;
  private hubConnectionMonitor: HubConnection;
  private hubConnectionNotificacoes: HubConnection;
  private baseUrl = environment.baseUrlMonitor;
  private baseUrlHubApreensoes = environment.baseUrlApreensoesService;
  public snackSuccess = new MatSnackBarConfig();
  public snackError = new MatSnackBarConfig();
  public signalRConnected: boolean;
  public signalRNotificacoesConnected: boolean;
  public isStarting = false;

  constructor(
    private snackBar: MatSnackBar,
    private servicoInterno: ServicosService,
    private notificacaoService: NotificacaoService
  ) {
    this.snackSuccess.duration = 5000;
    this.snackSuccess.panelClass = ["snackbar-success"];
    this.snackError.duration = 5000;
    this.snackError.panelClass = ["snackbar-error"];
  }

  get signalRStateConection() {
    return this.signalRConnected;
  }

  disconnectSignalR() {
    if (this.hubConnectionMonitor) {
      this.hubConnectionMonitor.stop();
      this.signalRConnected = false;
    }
  }

  openSnackBar(message, action, snackConfig) {
    this.snackBar.open(message, action, snackConfig);
  }

  createSignalRConnection(url: string) {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(this.baseUrl + url)
      .build();

    this.hubConnection.onclose(() => {
      this.signalRConnected = false;
    });
  }
  createSignalRGeneral(url: string) {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(url)
      .build();

    this.hubConnection.onclose(() => {
      this.signalRConnected = false;
    });
  }

  registerOnServerEvents(veiculoSelecionado: any): void {
    this.hubConnection.on("AtualizarLocalizacao", (veiculo: any) => {
      veiculo = JSON.parse(veiculo);
      veiculoSelecionado.latitude = veiculo.latitude;
      veiculoSelecionado.longitude = veiculo.longitude;
    });
  }

  registerOnServerEvent(event: string, action: any): void {
    this.hubConnection.on(event, (result: any) => action(result));
  }

  async startConnection() {
    await this.hubConnection
      .start()
      .then(() => {})
      .catch(() => {
        setTimeout(() => {
          this.startConnection();
        }, 5000);
      });
  }

  async startHashConnection(hash: string, onConnected?) {
    this.isStarting = true;

    await this.hubConnection
      .start()
      .then(() => {
        this.hubConnection.invoke("Subscribe", hash);
        this.openSnackBar("Rastreamento iniciado...", "x", this.snackSuccess);

        this.signalRConnected = true;
        this.isStarting = false;

        if (onConnected) {
          onConnected();
        }
      })
      .catch((e) => {
        setTimeout(() => {
          this.openSnackBar(
            "Problema a conectar. Tentando Novamente...",
            "x",
            this.snackError
          );
          this.startHashConnection(hash, onConnected);
        }, 5000);
      });
  }

  signalRInvoke = (nomeMetodo: string, hash: string, ...args) => {
    if (this.signalRConnected) {
      this.hubConnection.invoke(nomeMetodo, hash, ...args).catch((erro) => {
        this.errorHandler(erro.message);
        this.hubConnection.stop();
        this.signalRConnected = false;
      });
    } else {
      if (!this.isStarting) {
        this.startHashConnection(hash);
      }
    }
  };

  errorHandler(err: string): void {
    if (err.includes("HubException")) {
      err = err.substring(err.indexOf("HubException: ") + 14);
    }
    this.openSnackBar(err, "x", this.snackError);
  }

  createSignalRConnectionNotificacoes(url: string) {
    this.hubConnectionNotificacoes = new HubConnectionBuilder()
      .withUrl(this.baseUrl + url)
      .build();
  }

  registerOnServerEventNotificacoes(action: any): void {
    this.hubConnectionNotificacoes.on("NotificacoesSuporteRua", (result: any) =>
      action("NotificacoesSuporteRua", result)
    );
    this.hubConnectionNotificacoes.on(
      "NotificacoesRecolhimento",
      (result: any) => action("NotificacoesRecolhimento", result)
    );
  }

  async startConnectionNotificacoes() {
    await this.hubConnectionNotificacoes
      .start()
      .then(() => this.notificacaoService.emitConnectedToHub(true))
      .catch(() => {
        setTimeout(() => {
          this.startConnectionNotificacoes();
        }, 5000);
      });
  }
}
