import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { AuthGuard } from "./components/auth/auth.guard";
import { ExternalGuard } from "./components/auth/external.guard";
import { FabricaGuard } from "./components/auth/fabrica.guard";
import { APP_BASE_HREF } from "@angular/common";
import { LoginTemplateComponent } from "./components/pages/login-template/login-template.component";
import { MonitorProfileGuard } from "./components/auth/monitor.profile.guard";
import { InventoryManagerGuard } from "./components/auth/inventory-manager.guard";
import { RedirectModule } from "./components/pages/redirect/redirect.module";


const routes: Routes = [
  {
    path: "",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./components/pages/redirect/redirect.module").then(
        (m) => m.RedirectModule
      ),
  },
  {
    path: "home",
    canActivate: [AuthGuard, MonitorProfileGuard],
    loadChildren: () =>
      import("./components/pages/home-template/home-template.module").then(
        (m) => m.HomeTemplateModule
      ),
  },
  {
    path: "login",
    component: LoginTemplateComponent,
  },
  {
    path: "veiculo-selecao",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./components/pages/veiculo-selecao/veiculo-selecao.module").then(
        (m) => m.VeiculoSelecaoModule
      ),
  },
  {
    path: "app-fields",
    canActivate: [ExternalGuard, FabricaGuard, AuthGuard],
    loadChildren: () =>
      import("./components/pages/servicos-pwa/servicos.module").then(
        (m) => m.ServicosPwaModule
      ),
  },
  {
    path: "rastreio",
    loadChildren: () =>
      import("./components/pages/rastreio/rastreio.module").then(
        (m) => m.RastreioModule
      ),
  },
  {
    path: "rastreio-suporte",
    loadChildren: () =>
      import(
        "./components/pages/rastreio-suporte/rastreio-suporte.module"
      ).then((m) => m.RastreioSuporteModule),
  },
  {
    path: "servico-status/:id",
    loadChildren: () =>
      import("./components/pages/servico-status/servico-status.module").then(
        (m) => m.ServicoStatusModule
      ),
  },
  {
    path: "formulario-pagamento/:id",
    loadChildren: () =>
      import("./components/pages/formulario-pagamento/formulario-pagamento.module").then(
        (m) => m.FormularioPagamentoModule
      ),
  },
  {
    path: "home/ocorrencias/procuracoes",
    loadChildren: () =>
      import("./components/pages/ocorrencias/procuracoes/procuracoes.module").then(
        (m) => m.ProcuracoesModule
      ),
  },

  {
    path: "formulario-previsao-chegada/:hash",
    loadChildren: () =>
      import("./components/pages/formulario-previsao-chegada/formulario-previsao-chegada.module").then(
        (m) => m.FormularioPrevisaoChegadaModule
      ),
  },
  {
    path: "formulario-aceite-servico/:hash",
    loadChildren: () =>
      import("./components/pages/formulario-aceite-servico/formulario-aceite-servico.module").then(
        (m) => m.FormularioAceiteServicoModule
      ),
  },
  {
    path: "formulario-acompanhamento-servico/:hash",
    loadChildren: () =>
      import("./components/pages/formulario-acompanhamento-servico/formulario-acompanhamento-servico.module").then(
        (m) => m.FormularioAcompanhamentoServicoModule
      ),
  },
  {
    path: "acompanhamento-servico/:hash",
    loadChildren: () =>
      import("./components/pages/acompanhamento-servico/acompanhamento-servico.module").then(
        (m) => m.AcompanhamentoServicoModule
      ),
  },
  {
    path: "movimentacao/base",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        "./components/pages/controle-movimentacao-base/controle-movimentacao-base.module"
      ).then((m) => m.ControlemovimentacaoBaseModule),
  },
  {
    path: "inventario/apurar",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        "./components/pages/inventario/inventario-base.module"
      ).then((m) => m.InventarioBaseModule),
  },
  {
    path: "inventario/gerencia",
    canActivate: [InventoryManagerGuard],
    data: {
      role: 'fleet-inventory-manager'
    },
    loadChildren: () =>
      import(
        "./components/pages/unidades-module/dialogs/gestao-inventario/gestao-inventario.module"
      ).then((m) => m.GestaoInventarioModule),
  },
  {
    path: "inventario/pendencia",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        "./components/pages/inventario-pendencia/inventario-pendencia-base.module"
      ).then((m) => m.InventarioPendenciaBaseModule),
  },
  {
    path: "inventario/novos",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        "./components/pages/novos-veiculos/novos-veiculos-base.module"
      ).then((m) => m.NovosVeiculosBaseModule),
  },
  {
    path: "inventario-transferencia",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        "./components/pages/inventario-transferencia/inventario-transferencia.module"
      ).then((m) => m.InventarioTransferenciaModule),
  },
  {
    path: "area-atendimento",
    loadChildren: () =>
      import(
        "./components/pages/cobertura-atendimento/cobertura-atendimento.module"
      ).then((m) => m.CoberturaAtendimentoModule),
  },
  {
    path: "transferencia-minha-mottu",
    loadChildren: () =>
      import(
        "./components/pages/minha-mottu-form/minha-mottu-form.module"
      ).then((m) => m.MinhaMottuFormModule),
  },
  {
    path: 'formulario-minha-mottu',
    loadChildren: () => import('./components/pages/minha-mottu-form/minha-mottu-form.module')
      .then(module => module.MinhaMottuFormModule)
  },
  {
    path: 'minha-mottu-status-finalizacao/:usuarioId',
    loadChildren: () =>
      import(
        "./components/pages/minha-mottu-status-finalizacao/minha-mottu-status-finalizacao.module"
       ).then((m) => m.MinhaMottuStatusFinalizacaoModule),
  },
  {
    path: "terceiros-cadastro",
    loadChildren: () =>
      import(
        "./components/pages/cadastro-terceiros-form/cadastro-terceiro-form.module"
      ).then((m) => m.CadastroTerceiroFormModule),
  },
  {
    path: "confirm-email",
    loadChildren: () =>
      import(
        "./components/pages/confirm-email/confirm-email.module"
      ).then((m) => m.ConfirmEmailModule),
  },
  {
    path: 'diagnostico-remoto',
    loadChildren: () =>
      import(
        "./components/pages/fagbot/fagbot-main.module"
      ).then(m => m.FagbotMainModule)
  },
  {
    path: 'intercom',
    loadChildren: () =>
      import(
        "./components/pages/intercom/intercom.module"
      ).then(m => m.IntercomModule)
  },
  {
    canActivate: [AuthGuard, MonitorProfileGuard],
    path: 'terceiros',
    loadChildren: () =>
      import(
        "./components/pages/terceiros-admin-app/terceiros-admin-app.module"
      ).then(m => m.TerceirosAdminAppModule)
  },
  { path: "**", component: RedirectModule },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      relativeLinkResolution: "legacy",
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
  providers: [{ provide: APP_BASE_HREF, useValue: "/" }],
})
export class AppRoutingModule {}
