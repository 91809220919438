import { Injectable } from '@angular/core';
import { GoogleAddress } from '../models/GoogleAddress.model';
import { Subject } from 'rxjs';
declare const google: any;

@Injectable()
export class AutoCompleteGoogleService {
  private google;
  private autoComplete;
  googleAddressResponse = new Subject<GoogleAddress>();
  SERVICO_TIPO_ENUM;

  listenResponseGoogle(){
    return this.googleAddressResponse.asObservable();
  }

  searchLocation(searchInput: HTMLElement) {
    this.google = google;

    this.autoComplete = new this.google.maps.places.Autocomplete(searchInput, {
      types: ['geocode'],
    });

    this.autoComplete.setFields(['address_component']);

    google.maps.event.addListener(this.autoComplete, 'place_changed', () => {
      const places = this.autoComplete.getPlace();
      const addressResponse = this.parseAddressComponents(places.address_components);
      const geocoder = new google.maps.Geocoder();
      const address = `${addressResponse.rua}, ${addressResponse.numero} - ${addressResponse.bairro} - ${addressResponse.cidade} - ${addressResponse.uf} - Brasil - ${addressResponse.cep}`;

      geocoder.geocode({ address }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          addressResponse.latitude = results[0].geometry.location.lat().toFixed(7).toString();
          addressResponse.longitude = results[0].geometry.location.lng().toFixed(7).toString();
          const googleAddress = this.getFullAddress(addressResponse);
          this.googleAddressResponse.next(googleAddress);
        }
      });
    });
  }

  getAddressByLatLng(latitude: number, longitude: number) {
    const latlng = { lat: latitude, lng: longitude };
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({location: latlng}, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        const places = results[0];
        const fullAddress = results[0].formatted_address;

        const googleAddress = this.parseAddressComponents(places.address_components);
        googleAddress.latitude = latitude.toString();
        googleAddress.longitude = longitude.toString();
        googleAddress.fullAddress = fullAddress;

        this.googleAddressResponse.next(googleAddress);
      }
    });
  }

  private parseAddressComponents(components: Array<any>) {
    const addressResponse = new GoogleAddress();

    components.forEach(x => {
      switch (x.types[0]) {
        case 'postal_code':
          addressResponse.cep = x.short_name;
          break;
        case 'street_number':
          addressResponse.numero = x.long_name;
          break;
        case 'route':
          addressResponse.rua = x.long_name;
          break;
        case 'sublocality_level_1':
          addressResponse.bairro = x.long_name;
          break;
        case 'administrative_area_level_2':
          addressResponse.cidade = x.long_name;
          break;
        case 'administrative_area_level_1':
          addressResponse.uf = x.short_name;
          break;
      }
    });

    return addressResponse;
  }

  private getFullAddress(response: GoogleAddress): GoogleAddress {
    const numero = response.numero ? `, ${response.numero}` : '';
    response.fullAddress = `${response.rua}${numero} - ${response.bairro}, ${response.cidade} - ${response.uf}`;
    return response;
  }
}
